

export const MainSector = [
    {
        id:1,
        name:"House & Villa",
        route:"/Projects/House&Vila",
        state:"House & Vila",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414936/ARSA-PROJECT/knl7jb3jbiylifphoyhd.jpg",
        side:"left",
    },
    {
        id:2,
        name:"Residential Project",
        route:"/Projects/ResidentialProject",
        state:"Residential Projects",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663403674/ARSA-PROJECT/g4yvaoaqd0kcqouo1k3r.jpg",
        side:"right",
    },
    {
        id:3,
        name:"Administration Building",
        route:"/Projects/AdministrationBuilding",
        state:"Administration Building",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663403335/ARSA-PROJECT/n9enqbriwvxux43ymw0t.jpg",
        side:"left",
    },
    {
        id:4,
        name:"Commercial",
        route:"/Projects/Commercial",
        state:"Commercial",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663403205/ARSA-PROJECT/yvfysn95ewcimz3cmilw.jpg",
        side:"right",
    },
    {
        id:5,
        name:"Interior Design",
        route:"/Projects/InteriorDesign",
        state:"Interior Design",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663403577/ARSA-PROJECT/p8l36a6xfbjicn7bikev.jpg",
        side:"left",
    },
    {
        id:6,
        name:"Competition",
        route:"/Projects/Competition",
        state:"Competition",
        img:"https://images.unsplash.com/photo-1629774631914-55a9fef0f182?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YXJjaGljdHVyZXxlbnwwfHwwfHw%3D",
        side:"right",
    },
    {
        id:7,
        name:"Sport Project",
        route:"/Projects/SportProject",
        state:"Sport Project",
        img:"https://images.unsplash.com/photo-1624284366339-92d166dbca11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGFyY2hpY3R1cmUlMjBzcG9ydHxlbnwwfDB8MHx8",
        side:"left",
    },
    {
        id:8,
        name:"Industrial Project",
        route:"/Projects/IndustrialProject",
        state:"Industrial project",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414500/ARSA-PROJECT/uun7hhjxiarnamjgw4ua.jpg",
        side:"right",
    },
    {
        id:9,
        name:"Health Center",
        route:"/Projects/HealthCenter",
        state:"Health Center",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663403440/ARSA-PROJECT/uwdsclpgi2jh39vaont9.jpg",
        side:"left",
    },
    {
        id:10,
        name:"Parking Projects",
        route:"/Projects/ParkingProject",
        state:"Parking Project",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414569/ARSA-PROJECT/cxp71kr5lu9otq3l5xxz.jpg",
        side:"right",
    },
    {
        id:17,
        name:"Religion Projects",
        route:"/Projects/Religion",
        state:"Religion",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414569/ARSA-PROJECT/cxp71kr5lu9otq3l5xxz.jpg",
        side:"right",
    },
    {
        id:16,
        name:"Tourism Projects",
        route:"/Projects/Tourism",
        state:"Tourism Projects",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414569/ARSA-PROJECT/cxp71kr5lu9otq3l5xxz.jpg",
        side:"right",
    },
    {
        id:11,
        name:"Gates",
        route:"/Projects/GATES",
        state:"GATES",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414635/ARSA-PROJECT/juqrl8e7lzrhi5vsdmsr.jpg",
        side:"left",
    },
    {
        id:14,
        name:"Graphic Design",
        route:"/Projects/Graphic Design",
        state:"Graphic Design",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414635/ARSA-PROJECT/juqrl8e7lzrhi5vsdmsr.jpg",
        side:"right",
    },

    {
        id:15,
        name:"Animation",
        route:"/Projects/Animation",
        state:"Animation",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414635/ARSA-PROJECT/juqrl8e7lzrhi5vsdmsr.jpg",
        side:"left",
    },

    {
        id:12,
        name:"Others",
        route:"/Projects/Others",
        state:"Others",
        img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414681/ARSA-PROJECT/r7h155tavk8tv1ebmcs8.jpg",
        side:"right",
    },
    // {    
    //     id:13,
    //     name:"Other",
    //     route:"/Projects/Accomadation",
    //     state:"Accomadation",
    //     img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414681/ARSA-PROJECT/r7h155tavk8tv1ebmcs8.jpg",
    //     side:"right",
    // },
    // {
    //     id:14,
    //     name:"Other",
    //     route:"/Projects/Accomadation",
    //     state:"Accomadation",
    //     img:"http://res.cloudinary.com/dvcktacid/image/upload/v1663414681/ARSA-PROJECT/r7h155tavk8tv1ebmcs8.jpg",
    //     side:"right",
    // }
]