import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
      </Helmet>

      <div style={{ display: "flex", flexDirection: "column", margin: "0 auto", height: "120vh", justifyContent: "space-evenly" }} className="container_base">
        <div style={{ width: "100%", height: 60 }}></div>
        {/* Capital And Small */}
        <h1 style={{ fontFamily: "Satoshi-Medium", fontSize: 70, marginTop: 30, letterSpacing: 0 }}>Get in touch</h1>
        <img style={{ width: "100%", height: "auto", maxHeight: "80vh", objectFit: "cover" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1685635683/ARSA-PROJECT/rizl1to25kjdlp2mi29u.jpg" alt="" />
      </div>
      <div style={{ marginTop: 30, margin: "0 auto" }} className="container_base">
        <h1 style={{ fontFamily: "Satoshi-Bold", fontSize: 30, marginTop: 30, letterSpacing: 0, display: "block" }}>General Enquires</h1>
        <a href="mailto:INFO@ARSAGROUP.CO" style={{ fontFamily: "Satoshi-Medium", fontSize: 15, marginTop: 5, letterSpacing: 0 }}>
          INFO@ARSAGROUP.CO
        </a>
        <div style={{ width: "100%", height: 2, backgroundColor: "#000", marginTop: 80 }}></div>

        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <h1 style={{ fontFamily: "Satoshi-Medium", fontSize: 50, marginTop: 50, letterSpacing: 0, display: "block" }}>Sulaimanyah</h1>
          <svg style={{ marginTop: "auto", marginBottom: 10 }} width="20" height="20" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.4997 53.1673V27.5007M27.4997 27.5007V1.83398M27.4997 27.5007H53.1663M27.4997 27.5007H1.83301" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div style={{ width: "100%", height: 2, backgroundColor: "#000", marginTop: 40 }}></div>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <h1 style={{ fontFamily: "Satoshi-Medium", fontSize: 50, marginTop: 50, letterSpacing: 0, display: "block" }}>Erbil</h1>
          <svg style={{ marginTop: "auto", marginBottom: 10 }} width="20" height="20" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.4997 53.1673V27.5007M27.4997 27.5007V1.83398M27.4997 27.5007H53.1663M27.4997 27.5007H1.83301" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        <div style={{ width: "100%", height: 2, backgroundColor: "#000", marginTop: 40 }}></div>
        <img style={{ width: "100%", height: "auto", maxHeight: "100vh", margin: "40px 0", objectFit: "cover", objectPosition: "top" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1685635079/ARSA-PROJECT/fi4a9seibqb2nt3pu3ld.jpg" alt="" />
      </div>

      <Footer />
    </>
  );
}
