import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

function Career() {
  return (
    <div>
      <Helmet>
        <title>Career</title>
        <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
      </Helmet>

      <div style={{ width: "100%", height: "25vh" }} />
      {/* <div style={{width:'100%',margin:''}}> </div> */}

      <div style={{ margin: "0 auto", display: "flex", alignItems: "center", justifyContent: "space-between" }} className="container_base_team">
        <div className="cv_career">
          <h3 className="career_Titile">Careers.</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="career_p">Please send your application with a motivation letter, CV and portfolio (12MB, no download links or web links please) to:</h3>
          </div>
          <a href="mailto:info@gmail.com">info@arsagroup.co</a>
          <h3>In the email header, please state clearly the position and location you are applying for. We are looking forward to hearing from you!</h3>
        </div>

        <div className="carrer_image" style={{ width: "50%" }}>
          <img style={{ width: "100%", height: "100%" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1680434129/Both%20Ceo/rjyda4ci5pzgnortwbdh.jpg" alt="" />
        </div>
      </div>

      <div style={{ margin: "0 auto" }} className="container_base_team ffff">
        <img style={{ width: "100%", height: "100%" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1680434129/Both%20Ceo/rjyda4ci5pzgnortwbdh.jpg" alt="" />
      </div>

      <div style={{ width: "100%", height: "10vh" }}></div>

      <Footer />
    </div>
  );
}

export default Career;
