import { nanoid } from "nanoid";
import React from "react";
import { useLocation, useNavigate } from "react-router";

function Breadcrumb({ path, style }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div style={style} className="breadcrumb">
      {path.map((rank, i, arr) => {
        if (arr.length - 1 === i) {
          // last one

          return (
            <span onClick={() => location.pathname !== rank.route && navigate(rank.route)} className="active" key={nanoid(3)}>
              {rank.name}
            </span>
          );
        } else {
          // not last one
          return (
            <div key={nanoid(3)} className="breadcrumb_box">
              {rank.name === "Home" ? (
                <span onClick={() => location.pathname !== rank.route && navigate(rank.route)} className="Notactive">
                  <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8_2)">
                      <path d="M11.8724 4.87649L6.18421 0.711712C6.07452 0.631415 5.92551 0.631415 5.81587 0.711712L0.127631 4.87649C-0.0113068 4.97823 -0.0414943 5.17332 0.0602479 5.31226C0.16199 5.4512 0.357107 5.48134 0.496021 5.37964L6.00001 1.3497L11.504 5.37962C11.5595 5.42028 11.624 5.43985 11.6879 5.43985C11.7839 5.43985 11.8787 5.39567 11.9398 5.31224C12.0415 5.17332 12.0113 4.97823 11.8724 4.87649Z" fill="black" />
                      <path d="M10.365 5.45026C10.1929 5.45026 10.0533 5.58985 10.0533 5.76205V10.7249H7.55899V8.01587C7.55899 7.15618 6.85957 6.4568 5.99993 6.4568C5.14029 6.4568 4.44087 7.15623 4.44087 8.01587V10.7249H1.94658V5.76207C1.94658 5.58987 1.80696 5.45028 1.63479 5.45028C1.46262 5.45028 1.323 5.58987 1.323 5.76207V11.0367C1.323 11.2089 1.46262 11.3485 1.63479 11.3485H4.75265C4.91662 11.3485 5.0508 11.2219 5.06323 11.061C5.06398 11.0537 5.06444 11.0458 5.06444 11.0367V8.01589C5.06444 7.50005 5.48409 7.0804 5.99993 7.0804C6.51576 7.0804 6.93541 7.50008 6.93541 8.01589V11.0367C6.93541 11.0457 6.93588 11.0536 6.93663 11.0607C6.94891 11.2217 7.08314 11.3485 7.2472 11.3485H10.3651C10.5373 11.3485 10.6769 11.2089 10.6769 11.0367V5.76207C10.6768 5.58985 10.5372 5.45026 10.365 5.45026Z" fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_8_2">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                </span>
              ) : (
                <span onClick={() => location.pathname !== rank.route && navigate(rank.route)} className="Notactive">
                  {rank.name}{" "}
                </span>
              )}

              <svg width="14" height="14" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3784 8.70511L3.22473 0.551397C2.48953 -0.183799 1.21973 -0.183799 0.551367 0.551397C-0.183789 1.28659 -0.183789 2.48956 0.551367 3.22476L7.30161 10.0418L0.551406 16.792C-0.18375 17.5272 -0.18375 18.7302 0.551406 19.4654C0.885586 19.7995 1.42023 20 1.88805 20C2.35586 20 2.89059 19.7995 3.22473 19.4653L11.3784 11.3784C11.7126 11.0443 11.9131 10.5096 11.9131 10.0418C11.9131 9.57394 11.7126 9.03929 11.3784 8.70511ZM10.4428 10.4428L2.3559 18.5297C2.15539 18.7302 1.75442 18.7302 1.55391 18.5297C1.3534 18.3292 1.3534 17.9282 1.55391 17.7277L8.77196 10.5096C9.0393 10.2423 9.0393 9.84132 8.77196 9.57398L1.48707 2.35593C1.28656 2.08858 1.28656 1.7544 1.48707 1.55394C1.62074 1.42026 1.75441 1.35347 1.88809 1.35347C2.02176 1.35347 2.15543 1.42026 2.2891 1.55394L10.4428 9.70765C10.5765 9.84132 10.6433 9.975 10.6433 10.1087C10.5765 10.1754 10.5096 10.376 10.4428 10.4428Z" fill="black" />
              </svg>
            </div>
          );
        }
      })}
    </div>
  );
}

export default Breadcrumb;
