import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/youtube";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { API_BASE_URL } from "../Config/serverApiConfig";
import Breadcrumb from "./Breadcrumb";
import Footer from "./footer";

export default function ProjectSector(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  let video_arr = ["https://youtu.be/uQ0SMffhCwY?si=AqNw7YGVSEjIztSM","https://youtu.be/HBV0PeGNtyM", "https://youtu.be/REVQeA4GX3Q", "https://youtu.be/7Jom_tHb7pw", "https://youtu.be/kcPvUc9jWc0", "https://youtu.be/zI9Jy3s4UIQ", "https://youtu.be/7uZulsw-VQ8", "https://youtu.be/5yCqaSTyQ6o", "https://youtu.be/mXJklwcxe3I", "https://youtu.be/tQkUBbUK6jo", "https://youtu.be/DP6RwnDlC4Q"];
  const [count, setCount] = useState(0);
  const toTop = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const handlePath = (i) => {
    navigate(`/Projects/${location.state}/${i._id}`);
    dispatch({ type: "ROUTE_NAME", payload: i.name });
  };

  useEffect(() => {
    props.load(true);

    console.log(id);
    const fetchData = async () => {
      const result = await axios.get(`${API_BASE_URL}project?sector=${location.state}`);
      console.log(result.data);

      setData(result.data);
      setCount(result.data.length);
    };

    if (id !== "Animation") {
      fetchData();
    }
  }, []);

  return (
    <div ref={toTop}>
      <Helmet>
        <title>{location.state}</title>
        <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
      </Helmet>

      <div style={{ height: "8vh" }}></div>

      <div style={{ margin: "0 auto", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "column" }} className="flag_project_main">
        <div style={{ maxWidth: 1200, width: "93%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Breadcrumb
            style={{ marginBottom: 16 }}
            path={[
              { name: "Home", route: "/" },
              { name: "Project", route: "/Projects" },
              { name: location.state === "all" ? "All Project" : location.state, route: `/Projects/${id}` },
            ]}
          />
        </div>

        {id !== "Animation" && (
          <div style={{ maxWidth: 1200, width: "93%", display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
            <h1 className="header_projects">{location.state === "all" ? "All Project" : location.state}</h1>

            {location.state === "all" ? (
              <h1 style={{ fontSize: "1rem", fontFamily: "Satoshi-Medium" }} className="header_projects">
                {" "}
                {data.length} Projects
              </h1>
            ) : (
              <h1 style={{ fontSize: "1rem", fontFamily: "Satoshi-Medium" }} className="header_projects">
                {count} Projects
              </h1>
            )}
          </div>
        )}
      </div>

      {id === "Animation" ? (
        <div className="video_container">
          {video_arr.map((i, key) => {
            return (
              <div key={key} className="video_child">
                <ReactPlayer width={"100%"} url={i} />
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ marginTop: 40 }} className="projects">
          {data.map((i, key) => {
            return (
              <div key={key} onClick={() => handlePath(i)} className="project_child ">
                <LazyLoadImage effect="blur" width={"100%"} className="child_image_project" height={"100%"} style={{ width: "100%", height: "100%" }} src={i.primary} />


                <div className="project_child_name">
                  <h1>{i.name}</h1>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div style={{ width: "100%", height: "10vh" }}></div>

      <Footer />
    </div>
  );
}
