import React from "react";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../components/footer";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
      </Helmet>

      <div style={{ width: "100%", display: "flex", zIndex: 2, height: "30vh" }}>
        <div style={{ margin: "auto auto 0 auto", display: "flex" }} className="container_base">
          <h1 style={{ fontFamily: "Satoshi-Bold", fontSize: "5vw", marginTop: 30, letterSpacing: 0, margin: "0 auto" }}>We do social space</h1>
        </div>
      </div>

      <div style={{ margin: "0 auto", zIndex: 2, marginTop: 40 }} className="container_base_team img_abou_first">
        <img style={{ width: "100%", margin: "0 auto", zIndex: 20, objectFit: "cover" }} src={`https://res.cloudinary.com/dvcktacid/image/upload/v1685636422/ARSA-PROJECT/a74n2svdz82jai4tx4jk.jpg`} alt="" />
      </div>

      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ margin: "0 auto", marginTop: 40, zIndex: 2 }} className="container_base_team hThree">
          <h2 style={{ maxWidth: "840px", width: "100%", fontFamily: "Satoshi-Medium" }}>Founded by Kurdish architects Aree Abdulla & Sanagr Noory, ARSA is a cosmopolitan team of architect and designers in Sulaimanyah, Erbil with one central mission: to design the best social spaces in the world </h2>
        </div>
      </div>

      <div style={{ margin: "0 auto" }} className="container_base_team">
        <Marquee style={{ margin: "8vw auto 0 auto" }} gradient={false} speed={80}>
          <div className="box">
            <img src={`https://res.cloudinary.com/dvcktacid/image/upload/v1663344563/ARSA-PROJECT/exordhvpvq7fgqwvrtkw.jpg`} />
          </div>
          <div className="box">
            <img src={`https://res.cloudinary.com/dvcktacid/image/upload/v1663403205/ARSA-PROJECT/yvfysn95ewcimz3cmilw.jpg`} />
          </div>
          <div className="box">
            <img src={`https://res.cloudinary.com/dvcktacid/image/upload/v1663403674/ARSA-PROJECT/g4yvaoaqd0kcqouo1k3r.jpg`} />
          </div>
          <div className="box">
            <img src={`https://res.cloudinary.com/dvcktacid/image/upload/v1663403951/ARSA-PROJECT/lw2zmyht33wjmlxl2cqn.jpg`} />
          </div>
        </Marquee>
      </div>

      <div style={{ width: "100%", display: "flex", height: "20vh", alignItems: "flex-end" }}>
        <div style={{ margin: "0 auto", display: "flex" }} className="container_base_team">
          <span style={{ fontFamily: "Satoshi-Medium", fontSize: "1.3vw", textDecoration: "none", margin: "auto 0", marginRight: 10 }}>(02)</span>
          <div className="continuous-3">Our Services</div>
        </div>
      </div>

      <div style={{ width: "100%", display: "flex", marginTop: 60 }}>
        <div style={{ margin: "0 auto", display: "flex", flexDirection: "column" }} className="">
          <h2 style={{ fontFamily: "Satoshi-Bold", fontSize: "5.8vw" }}>We Do Inside-Outside Design.</h2>

          <div style={{ width: "80%", marginLeft: "auto", margin: "6vw 0 6vw auto" }}>
            <div className="our_services_paragraph">
              <h1>Architectural Design</h1>
              <p> Shaping the Future through Innovative Architecture</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Structural Design</h1>
              <p>Building Foundations, Engineering Brilliance: Redefining Structural Design for Unparalleled Strength and Stability.</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Mechanical Design</h1>
              <p>Pioneering Mechanical Design Solutions for Unmatched Performance and Comfort</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Electrical Design</h1>
              <p> Revolutionizing Electrical Design for a Sustainable Future</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Landscape Design</h1>
              <p>Crafting Captivating Landscape Designs that Embrace Beauty and Sustainability.</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Interior Design</h1>
              <p>Crafting Timeless Interior Designs that Enrich Lives and Enliven Environments</p>
            </div>

            <div className="our_services_paragraph">
              <h1>Consultancy</h1>
              <p>Expert Consulting for Architectural Excellence and Realizing Visionary Projects.</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ margin: "0 auto" }} className="container_base_team">
        <img style={{ width: "100%", height: "auto", maxHeight: "100vh", objectFit: "cover" }} src={`https://res.cloudinary.com/dvcktacid/image/upload/v1671984422/public/bepuztedhllvfafvlpev.jpg`} />
      </div>

      <div style={{ width: "100%", display: "flex", height: "20vh", alignItems: "flex-end" }}>
        <div style={{ margin: "0 auto", display: "flex" }} className="container_base_team">
          <span style={{ fontFamily: "Satoshi-Medium", fontSize: "1.3vw", textDecoration: "none", margin: "auto 0", marginRight: 10 }}>(03)</span>
          <div className="continuous-3">Our Team</div>
        </div>
      </div>

      <div style={{ width: "100%", display: "flex", marginTop: 60, marginBottom: "4vw" }}>
        <div style={{ margin: "0 auto", display: "flex", flexDirection: "column" }} className="container_base_team">
          <h2 style={{ fontFamily: "Satoshi-Bold", fontSize: "4.8vw" }}>We Are a Community of Optimists.</h2>
        </div>
      </div>

      <div className="container_base_team" style={{ marginTop: "8vw", margin: "0 auto", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ width: "35%" }}>
          <img style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }} src={"https://res.cloudinary.com/dvcktacid/image/upload/v1684757149/public/foybwltejbx5xqaglsol.jpg"} />
        </div>

        <div style={{ width: "62%" }}>
          <img style={{ width: "100%", height: "100%" }} src={`${process.env.PUBLIC_URL}https://res.cloudinary.com/dvcktacid/image/upload/v1680434129/Both%20Ceo/rjyda4ci5pzgnortwbdh.jpg`} />
        </div>
      </div>

      <div style={{ margin: "60px auto", display: "flex" }} className="container_base_team team_about">
        <div style={{}} className="team_about_p1">
          <p>To work at ARSA means to design optimistic architecture in an ambitious and joyful spirit, transcending borders and specialisation. </p>

          <Link to={"/team"}>Our Team</Link>
        </div>
        <div style={{}} className="team_about_p">
          <p>ARSA emphasises equal opportunities, and welcomes diversity and people of all backgrounds. If you would like to join our team, please head to the exciting career opportunities here. </p>
          <p style={{ margin: "30px 0" }}>As we grow our team，we remain socially conscious and and committed to foster individual development within the supportive atmosphere of our company networks. </p>

          <Link to={"/career"}>Careers</Link>
        </div>
      </div>

      <div className="container_base_team" style={{ margin: "0 auto" }}>
        <img style={{ width: "100%", height: "auto" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1680434581/Both%20Ceo/sd1fkdhmh0ahnxbvmadi.jpg" />
      </div>

      <div style={{ width: "100%", height: "10vh" }}></div>

      <Footer />
    </>
  );
}
