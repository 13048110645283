import React, { useEffect, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation, useNavigate, useParams } from "react-router";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import axios from "axios";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Navigation } from "swiper";
import { API_BASE_URL } from "../Config/serverApiConfig";
import Breadcrumb from "../components/Breadcrumb";

export default function ProjectDetail(props) {
  const { id } = useParams();
  const [count, setCount] = useState(1);
  const [hero, setHero] = useState([]);
  const nextElement = useRef(null);
  const navigate = useNavigate();
  const selector = useSelector((state) => state.routes);
  const location = useLocation();

  useEffect(() => {
    props.load(true);
    const fetchData = async () => {
      const result = await axios.get(`${API_BASE_URL}project/${id}`);
      setHero(result.data);
    };
    fetchData();
    props.setDisplay(false);

    return () => {
      props.setDisplay(true);
    };
  }, []);

  if (hero.length === 0) {
  } else {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <Helmet>
          <title>{hero[0].name}</title>
          <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
        </Helmet>

        <div style={{ margin: "0 auto", height: "100%", display: "block" }} className="container_base">
          {/* Nav */}

          <div
            style={{
              margin: "0 auto",
              maxWidth: 1200,
              width: "93%",
              flexWrap: "wrap",
              display: "flex",
              height: "25vh",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* yakam */}
            <div
              style={{
                //    width: "28%",
                //  maxWidth:"90%",
                display: "flex",
                //  height: "30%",
                flexDirection: "column",
              }}
              className="jsjsjsj"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  height: "100%",

                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="project_detail_name"
                  style={{
                    display: "flex",

                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <h1>{hero[0].name}</h1>
                </div>

                <div
                  className="project_detail_sector"
                  style={{
                    height: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    width: "50%",
                  }}
                >
                  <div style={{ width: 1, height: "80%", backgroundColor: "#000" }}></div>

                  <h1>{hero[0].sector}</h1>
                </div>
              </div>
              <h1 className="project_detail_year">{hero[0].year}</h1>
            </div>
            {/*  Dwam  */}
            <div className="back_project_list">
              <Breadcrumb
                //  style={{ marginBottom: 16 }}
                path={[
                  { name: "Home", route: "/" },
                  { name: "Project", route: -2 },
                  { name: selector?.project, route: -1 },
                  { name: selector?.projectDetail, route: 0 },
                ]}
              />
            </div>
          </div>
          {/* Body */}

          <div className="body_project_detail">
            <Swiper style={{ backgroundColor: "#F0eff1" }} onNavigationNext={() => setCount(count === hero[0].src.length ? 1 : count + 1)} onNavigationPrev={() => setCount(count === 1 ? hero[0].src.length : count - 1)} loop={true} navigation={{ nextEl: ".right_arrow_slide", prevEl: ".left_arrow_slide" }} modules={[Navigation]} className="mySwiper">
              {hero[0].src.map((item) => {
                return (
                  <SwiperSlide style={{ backgroundColor: "#F0eff1" }}>
                    <LazyLoadImage effect="blur" width={"100%"} height={"100%"} style={{ width: "100%", height: "100%", objectFit: "contain" }} src={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="fraction">
            <h1>
              ({count}/{hero[0].src.length})
            </h1>
          </div>

          <div className="left_arrow_slide arrow_slide">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24v-1z" fill="#232326" />
            </svg>
          </div>

          <div className="right_arrow_slide arrow_slide">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M17.5 5.999l-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" fill="#232326" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
