import { animate } from "framer-motion";
import { gsap } from "gsap";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

let width = window.innerWidth;

function Counter({ from, to }) {
  const nodeRef = useRef();
  const lineRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;
    const node2 = lineRef.current;

    const controls = animate(from, to, {
      duration: 8,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
        node2.style.width = `${value.toFixed(10)}%`;
      },
    });

    animate(from, to - 4, {
      duration: 8,
      onUpdate(value) {
        node2.style.width = `${value.toFixed(10)}%`;
      },
      onComplete() {
        animate(1, 0, {
          duration: 1,
          onUpdate(value) {
            node.style.opacity = `${value}`;
            node2.style.opacity = `${value}`;
          },
        });
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return (
    <>
      <p className="counter_loader" style={{ position: "absolute", bottom: "14%", right: "7%" }} ref={nodeRef} />
      <div className="preloader_line_top" ref={lineRef}></div>
    </>
  );
}

const Loader = () => {
  const childNode = useRef(null);
  const dispatch = useDispatch();

  //                         28                                   18       28      28      26      19
  let arr = width < 500 ? ["160px", "240px", "240px", "210px", "180px"] : ["250px", "420px", "420px", "380px", "260px"];

  useEffect(() => {
    var vSlide = gsap.timeline({ onComplete: () => dispatch({ type: "SET_LOADER_FALSE" }) });

    childNode.current.childNodes.forEach(function (slide, i) {
      // Create a label
      let wid = "";
      let label = "slide" + i;
      vSlide.add(label);
      if (i === 0) {
        wid = arr[0];
      } else if (i === 1) {
        wid = arr[1];
      } else if (i === 2) {
        wid = arr[2];
      } else if (i === 3) {
        wid = arr[3];
      } else if (i === 4) {
        wid = arr[4];
      }

      // Move the whole word
      vSlide.to(
        childNode.current,
        {
          duration: 0.5,
          y: i * -1 * 60,
          width: wid,
        },
        label
      );
      vSlide.to({}, { duration: 1 });
    });
    vSlide.to(".container", {
      y: -200,
      duration: 2,
    });
    vSlide.to(".holymoly", {
      yPercent: 100,
      duration: 0.4,
    });
  }, []);

  return (
    <div style={{ height: "100vh", backgroundColor: "#f2f2f2", position: "fixed", zIndex: 10000, top: 0, right: 0, left: 0 }} className="holymoly">
      <Counter from={0} to={100} />

      <div className="dss">
        <div className="container">
          <h2 className="v-slide" style={{ marginRight: "1vw" }}>
            WE ARE
          </h2>

          <div ref={childNode} className="span_container ">
            <span className="v-slide">ARCHITECTS</span>
            <span className="v-slide">INTERIOR DESIGNERS</span>
            <span className="v-slide">PROJECT MANAGERS</span>
            <span className="v-slide">URBAN DESIGNERS</span>
            <span className="v-slide">ARSA GROUP</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
