
//http://localhost:5000/
//https://arsa-addimag-dqxv2unrw-nwekar-ns20.vercel.app/
//https://arsa-addimag-c4d9a1fyd-nwekar-ns20.vercel.app/
export const API_BASE_URL = "https://arsa-addimage-production.up.railway.app/"
// export const API_BASE_URL = "http://localhost:5000/"
// export const API_BASE_URL = "http://192.168.1.146:5000/"


// export const API_BASE_URL = "https://starter-mern.herokuapp.com/api/";
export const ACCESS_TOKEN_NAME = "x-auth-token"

export const headers = {
    headers: {
        'Content-Type': 'application/json'
    }
};
     


export const HTTP_STATUS = Object.freeze({
    PENDING:"PENDING",
    FULFILLED:"FULFILLED",
    REJECTED:"REJECTED"
})
