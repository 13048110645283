import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import Routing from './Routing';
import Store from './store';
import './sass/main.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <Provider store={Store}>
            
    <Routing />
   
    </Provider>



);

