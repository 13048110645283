export const AllTeam = [
  {
    id: 6,
    name: "Azad Shekhani",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685618162/public/otesxhjnnzokciybmyow.jpg",
    position: "Architect & Urban Planner",
    secondaryPosition: "Consultant",
  },
  {
    id: 4,
    name: "Amjad Mustafa",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685628433/public/hu4rymsgb00qfs2rrd3v.jpg",
    position: "Structural Engineer",
    secondaryPosition: "Consultant",
  },
  {
    id: 5,
    name: "Taha Omer",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685618131/public/ifeokscgxbyq32m5m2jm.jpg",
    position: "Mechanical Engineer",
    secondaryPosition: "Consultant",
  },
  {
    id: 8,
    name: "Azad Faraj",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685619912/public/bnngxtbjija4nabhirge.jpg",
    position: "Electrical Engineer",
    secondaryPosition: "Consultant",
  },
  {
    id: 1,
    name: "Sarwat Hassan Ahmed",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684335624/kak%20sarwat/zhkolkgvwa9mnb82aipx.jpg",
    position: "Structural Engineer",
    secondaryPosition: "Consultant",
  },
  {
    id: 3,
    name: "Zring Rawf Darwesh",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684335412/Kak%20zrng/bt6euuzkjtbtjjnxzjno.jpg",
    position: "Electrical Engineer",
    secondaryPosition: "Consultant",
  },

  {
    id: 11,
    name: "Jamal HamaAmin",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684754504/Kak%20jamal/ei6uaffhdlqgdiw4or1f.jpg",
    position: "Mechanical Engineer",
    secondaryPosition: "",
  },
  {
    id: 21,
    name: "Shazad Jaseem Ahmed",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684337278/Kak%20shazad/a7gdwkxccwpiwib3my1a.jpg",
    position: "Architect",
    secondaryPosition: "Consultant",
  },

  {
    id: 2,
    name: "Zana Abdullrahman",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685632917/public/tnul6u3mjacqaq3cqila.jpg",
    position: "Architect",
    secondaryPosition: "Consultant",
  },

  {
    id: 12,
    name: "Rawezh Rasul Muhamad",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684754563/Kak%20rawezh/nviwtz2cozquzcan8ldg.jpg",
    position: "Architect",
    secondaryPosition: "",
  },

  {
    id: 13,
    name: "Dana T. Hussein",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684335082/Kak%20Dana/yksfhd7dcrqyutfwgrea.jpg",
    position: "Architect",
    secondaryPosition: "",
  },
  {
    id: 14,
    name: "Akar Mahdi Shareef",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685631565/public/nt4e4venx1f7oxuj3brm.jpg",
    position: "Architect",
    secondaryPosition: "",
  },
  {
    id: 15,
    name: "Bryar Dhair Ali",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684336318/Kak%20Bryar/xkhm5cqpofgsa5zpuumo.jpg",
    position: "Architect",
    secondaryPosition: "",
  },
  {
    id: 16,
    name: "Rebaz Karim",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685633477/public/hcp8imh9t8u2mguee6xn.jpg",
    position: "Structural Engineer",
    secondaryPosition: "",
  },

  {
    id: 22,
    name: "Mohammed Abbas",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684584546/Muhamed%20Decorat/aw4ucgiovvxndql5ihjd.jpg",
    position: "Urban Planner",
    secondaryPosition: "",
  },
  {
    id: 17,
    name: "Mohammed Jabbar Abbdullah",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685619806/public/hda4iadvzgznh43yjiox.jpg",
    position: "Architect",
    secondaryPosition: "",
  },
  {
    id: 18,
    name: "Mohammed Adil Mohammed",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1685619811/public/yqrmtry42uvpmoqksoup.jpg",
    position: "Architect",
    secondaryPosition: "",
  },

  {
    id: 18,
    name: "Sivar Mustafa Rasul",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1689867248/public/b1ooxnia6jzl19ywf0tn.jpg",
    position: "Urban Planner",
    secondaryPosition: "",
  },
  {
    id: 19,
    name: "Lawa Khalid Mohammed",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684335925/Kak%20Lawa/gdaah8qp8rjntwdav0dp.jpg",
    position: "Draftsman",
    secondaryPosition: "",
  },
  {
    id: 20,
    name: "Shad Abdullqader",
    img: "https://res.cloudinary.com/dvcktacid/image/upload/v1684336703/shad/fu04gsvsbdpmywc527nb.jpg",
    position: "Draftsman",
    secondaryPosition: "",
  },
];
