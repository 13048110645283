import React from "react";
import { Helmet } from "react-helmet";
import { AllTeam } from "../Data/teamName";
import Footer from "../components/footer";

function Team() {
  return (
    <div>
      <Helmet>
        <title>Team</title>
        <meta name="description" content="Arsa Group combines decades of experience with fresh perspectives to create architectural designs that are aesthetically pleasing and highly functional" />
      </Helmet>

      <div style={{ width: "100%", height: "85px" }} />
      <div className="team_top_container" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
        <div style={{ margin: "0 auto", zIndex: 2, display: "flex", justifyContent: "space-between", alignItems: "flex-end", flexWrap: "wrap" }} className="container_base_team">
          <h2 className="team_title" style={{ maxWidth: "740px", width: "100%", fontFamily: "Satoshi-Medium", fontSize: 100 }}>
            Our Team.
          </h2>

          <p className="team_title_paragraph" style={{ fontFamily: "Satoshi-Medium" }}>
            Collective of Passinate Architects and Designers
          </p>
        </div>
      </div>

      <div style={{ margin: "0 auto" }} className="container_base_team">
        <div style={{ width: "100%", height: 1, backgroundColor: "#222", margin: "auto", marginTop: 20 }} />
      </div>

      <div style={{ margin: "0 auto", zIndex: 2, display: "flex", justifyContent: "space-between", marginTop: 40, flexWrap: "wrap" }} className="container_base_team">
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <h3 className="team_second_first_paragraph" style={{ fontFamily: "Satoshi-Medium", fontSize: 30 }}>
            At ARSA, we have a team of more than 30 experienced architects and designers, who enjoy working collaboratively and imaginatively on our extensive range of projects{" "}
          </h3>
          <h3 className="team_second_second_paragraph" style={{ fontFamily: "Satoshi-Medium", fontSize: 16 }}>
            Led by Aree & Sangar, the ARSA team is a community of talents from all over the world, a new generation of extremely creative yet commercially experienced designers who actively seek to promote architecture’s power to connect people{" "}
          </h3>
        </div>

        <div className="" style={{ width: 500, display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
          <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1678750394/Both%20Ceo/aqubn6i0bnbmogodoppt.jpg" alt="" />
        </div>
      </div>

      <div style={{ margin: "0 auto", zIndex: 2, height: "100vh" }} className="container_base_team team_fourth_image">
        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="https://res.cloudinary.com/dvcktacid/image/upload/v1684758421/public/sykytflehgmftb30grko.jpg" alt="" />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "auto" }} className="container_base_team">
        <h4 style={{ margin: "60px 0", fontFamily: "Satoshi-Medium", fontSize: 60 }}>Team.</h4>
      </div>

      <div style={{ margin: "auto", display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginBottom: 100 }} className="container_base_team ">
        <div style={{ width: 300, height: 400, display: "flex", flexDirection: "column" }}>
          <div style={{ width: 300, height: 400, display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }} className="team_fifth_image">
            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={"https://res.cloudinary.com/dvcktacid/image/upload/v1684584461/Kak%20Aree/v0wg4gapqnukfr5cfbgj.jpg"} alt="" />
          </div>

          <h4 style={{ fontFamily: "Satoshi-Medium", fontSize: 18, marginTop: 6 }}>
            Aree Abdulla Saeed <span>(AR)</span>
          </h4>
          <p style={{ fontFamily: "Satoshi-Medium", fontSize: 14, marginTop: 6, marginBottom: 8, color: "#9e9e9e" }}>Co-Founder (Consultant Architect)</p>
        </div>

        <div className="hello" style={{ width: 300, height: 400, display: "flex", flexDirection: "column" }}>
          <div style={{ width: 300, height: 400, display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }} className="team_fifth_image">
            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={"https://res.cloudinary.com/dvcktacid/image/upload/v1685631058/public/rujoy6a6exuenc296tsk.jpg"} alt="" />
          </div>

          <h4 style={{ fontFamily: "Satoshi-Medium", fontSize: 18, marginTop: 6 }}>
            Sangar Noory Majid <span>(SA)</span>
          </h4>
          <p style={{ fontFamily: "Satoshi-Medium", fontSize: 14, marginTop: 6, marginBottom: 8, color: "#9e9e9e" }}>Co-Founder (Consultant Architect)</p>
        </div>
      </div>

      <div style={{ margin: "auto", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }} className="container_base_team jjj">
        {AllTeam.map((item) => {
          return (
            <div style={{ width: 250, height: 400, display: "flex", flexDirection: "column" }} className="kskksks">
              <div style={{ width: 250, height: 300, display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }} className="team_fifth_image kkkk">
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={item.img} alt="" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4 style={{ fontFamily: "Satoshi-Medium", fontSize: 18, marginTop: 6 }}>{item.name}</h4>
                <p style={{ fontFamily: "Satoshi-Medium", fontSize: 14, marginTop: 6, marginBottom: 8, color: "#9e9e9e" }}>
                  {item.secondaryPosition === "" ? "" : `(${item.secondaryPosition})`} {item.position} {item.id === 14 ? `(Graphic Designer)` : ""}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ width: "100%", height: "20vh" }} />

      <Footer />
    </div>
  );
}

export default Team;
