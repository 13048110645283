import axios from "axios";
import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HoverVideoPlayer from "react-hover-video-player";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../Config/serverApiConfig";
import { MainSector } from "../Data/main";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/footer";

export default function Projects(props) {
  const [sector, setSector] = useState(MainSector);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    props.load(true);

    const fetchData = async () => {
      const result2 = await axios.get(`${API_BASE_URL}project/sectionMain`);
      let data = MainSector;
      let data2 = result2.data;
      let t2t = data.map((item, index) => ({ ...item, src: data2[index].src }));

      setSector(t2t);
    };
    fetchData();
  }, []);

  const handlePath = (i, v) => {
    navigate(i, { state: v });
    dispatch({ type: "DETAIL_NAME", payload: v });
  };

  return (
    <>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Dive into Arsa's extensive portfolio showcasing a blend of traditional craftsmanship and contemporary design in architectural projects." />
      </Helmet>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} exit={{ opacity: 0 }}>
        <div style={{ height: "15vh" }}></div>

        <div className="project_page_header">
          <h1>Explore Our Wide Range of Projects</h1>
          <p>Discover our exceptional architectural,structural,electrical and mechanical projects that span across various sectors, showcasing our expertise in mechanical, architectural, electrical, and structural engineering. Click on each sector below to explore our remarkable portfolio and witness the innovation and excellence we bring to every project</p>
        </div>

        <div style={{ width: "94%", margin: "0 auto" }}>
          <HoverVideoPlayer muted={false} videoSrc={`https://res.cloudinary.com/dvcktacid/video/upload/v1686348594/ARSA-PROJECT/re3acm3j9qyhlbqwashp.mp4`} loadingOverlay={<div className="loading-overlay">loading...</div>} />
        </div>

        <div style={{ margin: "0 auto", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "column" }} className="flag_project_main">
          <div style={{ maxWidth: 1200, width: "93%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Breadcrumb
              style={{ marginBottom: 16 }}
              path={[
                { name: "Home", route: "/" },
                { name: "Project", route: "/Projects" },
              ]}
            />
          </div>

          <div style={{ maxWidth: 1200, width: "93%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h1 className="header_projects">Project Type</h1>

            <button
              onClick={() => {
                navigate("/Projects/All", { state: "All" });
                dispatch({ type: "DETAIL_NAME", payload: "All Projects" });
              }}
              className="button button--hyperion"
            >
              <span>
                <span>All Projects</span>
              </span>
            </button>
          </div>
        </div>

        <div style={{ maxWidth: 1400, width: "100%", margin: "40px auto" }} className="home_sector">
          <div className="home_sector_inner">
            {/* 1 */}

            {sector.map((item) => {
              return (
                <div key={nanoid(3)} onClick={() => handlePath(item.route, item.state)} className="home_sector_card home_sector_card_image prhch">
                  <div id="overlay" style={{ position: "absolute", width: "100%", height: "100%", zIndex: 1, backgroundColor: "#000", top: 0, left: 0, right: 0, opacity: 0.3 }}></div>
                  {item.name === "Animation" ? (
                    <>
                      <video  autoPlay loop>
                        <source src={`${process.env.PUBLIC_URL}Anim4.mp4`} type="video/mp4" />
                      </video>
                      <h1 className="sector_text" style={{ position: "absolute", bottom: "5%", left: "10%", zIndex: 2, color: "#fff", fontFamily: "Satoshi-Medium" }}>
                        {item.name}
                      </h1>
                    </>
                  ) : (
                    <>
                      <img id="imaf" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={item.src} alt="" />
                      <h1 className="sector_text" style={{ position: "absolute", bottom: "5%", left: "10%", zIndex: 2, color: "#fff", fontFamily: "Satoshi-Medium" }}>
                        {item.name}
                      </h1>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
}
