import { createStore } from "redux";




const init ={
    initLang:"en",
    loader:true,
    routes:{
        project:'',
        projectDetail:'',
    }
}

const langReducer = (state = init,action) => {

    switch(action.type){
        case "CHANGE_LANG":
            return{initLang:action.payload};

            case "ROUTE_NAME":
                return{
                    ...state,
                    routes:{
                        project:state.routes.project,
                        projectDetail:action.payload
                    }
                }

                case "DETAIL_NAME":
                    return{
                        ...state,
                        routes:{
                            project:action.payload,
                            projectDetail:state.routes.projectDetail
                        }
                    }

            case "SET_LOADER_FALSE":
                return{
                    ...state,
                    loader:false
                }

                case "SET_LOADER_TRUE":
                    return{
                        ...state,
                        loader:true
                    }

                    case "SET_PROJECT_ROUTE":
                        return{
                            ...state,
                            routes:action.payload,

                        }

            default:
                return state

    }

 

}




const Store = createStore(
    langReducer,
  
)

export default Store;