import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap/all";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { Outlet, Route, RouterProvider, ScrollRestoration, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Career from "./Pages/Career";
import Team from "./Pages/Team";
import About from "./Pages/about";
import Contact from "./Pages/contact";
import ProjectDetail from "./Pages/projectDetail";
import Projects from "./Pages/projects";
import Nav from "./components/Header";
import Loader from "./components/Loader";
import Main from "./components/Main";
import ProjectSector from "./components/ProjectSector";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
ReactGA.initialize("UA-162886099-1");

function Routing() {
  const [display, setDisplay] = useState(true);
  const [loadTransition, setLoadTransition] = useState(false);
  const selectorLoader = useSelector((state) => state.loader);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <>
            {selectorLoader ? <Loader /> : null}
            {display && <Nav />}

            <main>
              <Outlet />
            </main>

            <ScrollRestoration
              getKey={(location, matches) => {
                return location.pathname;
              }}
            />
          </>
        }
      >
        <Route index path="/" element={<Main />} />
        <Route path="about" element={<About />} />
        <Route path="team" element={<Team />} />
        <Route path="career" element={<Career />} />
        <Route path="projects" element={<Projects load={setLoadTransition} />} />
        <Route path="projects/:id" element={<ProjectSector load={setLoadTransition} />} />
        <Route path="projects/:id/:id" element={<ProjectDetail load={setLoadTransition} setDisplay={setDisplay} />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    )
  );

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default Routing;
